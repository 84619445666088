'use client'

import axios, { AxiosInstance, AxiosError } from 'axios'
import { PUBLIC_SITE_URL } from '../constants'
import {
  InfinitedResponse,
  Website,
  WebsiteVersion,
  WebsiteWithVersion,
  WebsiteSchema,
} from '@repo/common/entities'
import { AppRouterInstance } from 'next/dist/shared/lib/app-router-context.shared-runtime'

export class UnauthorizedError extends Error {
  constructor(public resErr: AxiosError) {
    super(resErr.message)
    this.name = 'UnauthorizedError'
  }
}

export class APIClient {
  private client: AxiosInstance
  private davinciClient: AxiosInstance

  constructor(private router: AppRouterInstance) {
    this.client = axios.create({
      baseURL: PUBLIC_SITE_URL,
      timeout: 120 * 1000,
    })

    this.davinciClient = axios.create({
      baseURL: process.env.DAVINCI_API_URL,
      timeout: 120 * 1000,
    })

    this.setupInterceptors()
  }

  private setupInterceptors() {
    this.client.interceptors.response.use(
      (response) => response,
      (error: AxiosError) => {
        if (error.response?.status === 401) {
          console.log('Unauthorized, redirect to login')
          this.redirectToLogin()
          return Promise.reject(new UnauthorizedError(error))
        }
        return Promise.reject(error)
      }
    )
  }

  private redirectToLogin() {
    const currentHref = window.location.href
    const currentUrl = new URL(currentHref)
    const nextUrl = new URL('/login', PUBLIC_SITE_URL)
    nextUrl.searchParams.set('next', currentUrl.toString())
    // redirect to login page
    // window.location.href = nextUrl.toString();
    this.router.push(nextUrl.toString())
  }

  async get<T>(url: string, params?: any): Promise<T> {
    const response = await this.client.get<T>(url, { params })
    return response.data
  }

  async post<T>(url: string, data?: any): Promise<T> {
    const response = await this.client.post<T>(url, data)
    return response.data
  }

  async createWebsite(prompt: string, image?: string | null) {
    const response = await this.davinciClient.post<{ id: string }>(
      '/api/websites/create',
      { prompt, image }
    )
    return response.data
  }

  async editWebsite(versionId: string, prompt: string, image?: string | null) {
    return this.post<{ id: string }>(`/api/versions/${versionId}/edit`, {
      prompt,
      image,
    })
  }

  async getLatestWebsites({
    cursor,
    featured,
    userId,
  }: {
    cursor?: string
    featured?: boolean
    userId?: string
  }) {
    return this.get<InfinitedResponse<typeof WebsiteSchema>>(
      '/api/websites/latest',
      { cursor, featured, userId }
    )
  }

  async getMineWebsites({ cursor }: { cursor?: string }) {
    return this.get<InfinitedResponse<typeof WebsiteSchema>>(
      '/api/websites/mine',
      { cursor }
    )
  }

  async getRecommendedWebsites({ did }: { did?: string }) {
    return this.get<Website[]>('/api/websites/recommend', { did })
  }

  async getWebsite(wid: string) {
    return this.get<WebsiteWithVersion>(`/api/websites/${wid}`)
  }

  async getVersion(vid: string) {
    return this.get<WebsiteVersion>(`/api/versions/${vid}`)
  }

  async revokeVersion(vid: string) {
    return this.post<{ id: string }>(`/api/versions/${vid}/revoke`)
  }
}
